import React from 'react'
import { NavLink } from "react-router-dom"
import styles from '../styles/Menu.module.css'

const Menu = () => {
    return (
        <nav className={styles.menu}>
          <NavLink to='/' end>Головна</NavLink>
          <NavLink to='/news'>Новини</NavLink>
          <NavLink to='/services'>Послуги</NavLink>
          <NavLink to='/tarif'>Тарифи</NavLink>
          <NavLink to='/balance'>Стан рахунку</NavLink>
          <NavLink to='/about'>Про нас</NavLink>
        </nav>
    )
}

export default Menu
