import React from 'react'

const News = () => {
  return (
    <div>
         <h4>Нові тарифи</h4>

<p>
З 1 січня 2025 року змінюється вартість тарифів за послуги інтернету. Причиною цьому є маса факторів, які не дають змоги залишатися на старих тарифах. 
Це: зміни у податковому оподаткуванні, збільшення комунальних тарифів, збільшення вартості обслуговування та утримання інтернет мережі. 
Вартість інтернету швидкістю до 100 Мбіт/с складатиме 230 грн, швидкістю до 300 Мбіт/с - 300 грн, пакет з постійною ip-адресою складатиме 400 грн.
</p>
    </div>
  )
}

export default News