import React from 'react'
import Baner from '../images/bn.jpg'
import styles from '../styles/Header.module.css'


const Header = () => {
  return (
    <div className={styles.baner}>
        <img src={Baner} alt="" />

    </div>
  )
}


export default Header