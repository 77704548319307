import React, { useEffect } from "react";
import Menu from '../components/Menu';
import Header from "./Header";
import Footer from "./Footer";
import styles from '../styles/Template.module.css'; // Подключаем стили

const Template = ({ children }) => {

  // Функция для генерации снежинок
  useEffect(() => {
    const snowflakes = 50; // Количество снежинок
    const container = document.querySelector(`.${styles.snowflakeContainer}`);

    for (let i = 0; i < snowflakes; i++) {
      const snowflake = document.createElement('div');
      snowflake.classList.add(styles.snowflake);
      snowflake.textContent = '❄'; // Символ снежинки
      snowflake.style.left = `${Math.random() * 100}vw`; // Случайное положение по горизонтали
      snowflake.style.animationDuration = `${Math.random() * (15 - 10) + 10}s`; // Разные скорости падения

      container.appendChild(snowflake);
    }
  }, []);

  return (
    <div className={styles.template}>
      <Header />

      <div className={styles.menu}>
        <Menu />
      </div>

      <div className={styles.body}>
        <div className={`${styles.left} ${styles.item}`}></div>
        <div className={`${styles.centr} ${styles.item}`}>
          {children} {/* Отображаем компоненты Home и About здесь */}
        </div>
        <div className={`${styles.right} ${styles.item}`}></div>
      </div>

      {/* Контейнер для снежинок */}
      <div className={styles.snowflakeContainer}></div>

      <Footer />
    </div>
  );
};

export default Template;

