import React from 'react'
import styles from '../styles/Tarif.module.css'

const Tarif = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Наші тарифи (до 01.01.2025 р.)</h1>
      <ul className={styles.tarifList}>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>100 Мб/с</div>
          <div className={styles.price}>200 грн/міс</div>
        </li>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>300 Мб/с</div>
          <div className={styles.price}>260 грн/міс</div>
        </li>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>Статичний IP</div>
          <div className={styles.price}>320 грн/міс</div>
        </li>
      </ul>
    </div>
  )
}

export default Tarif
