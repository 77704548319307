import React from 'react';
import styles from '../styles/Footer.module.css';
import Pays from '../images/pays.jpg';

const Footer = () => {
  const handleImageClick = () => {
    window.location.href = 'https://next.privat24.ua/payments/form/%7B%22token%22:%22691a9dd7-25e4-4bd8-aeb9-35c1d56d5457%22%7D';
  };

  return (
    <div className={styles.footer}>
      <img
        src={Pays}
        alt="Платежи"
        className={styles.footerImage}
        onClick={handleImageClick}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default Footer;
