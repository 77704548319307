import { BrowserRouter,Routes,Route } from 'react-router-dom';
import './App.css';

import News from './components/News';
import Services from './components/Services';

import Tarif from './components/Tarif';
import About from './components/About';
import AccountBalance from './components/AccountBalance';
import MainLayout from './layouts/MainLayout';
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>
    <Route element={<MainLayout>
      
    </MainLayout>} path='/'>
      <Route index element={<Home />}/>
      <Route element={<News />} path='news' />
      <Route element={<Services />} path='services' />

   
      <Route element={<Tarif />} path='tarif' />
      <Route element={<AccountBalance />} path='balance' />
<Route element={<About />} path='about'  />
    <Route element={<h1>Not Found</h1>} path='*' />
      

    </Route>
    
    </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
